// Generated by Framer (cec372b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KCknGM1rc"];

const variantClassNames = {KCknGM1rc: "framer-v-1opdvy2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string}; logo?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "KCknGM1rc", image: IHI30114s, logo: ZrWmJpbzC, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KCknGM1rc", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-08lhz", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1opdvy2", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"KCknGM1rc"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fill", ...toResponsiveImage_194x2gw(IHI30114s)}} className={"framer-6iumg8"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"evmz2kCLd"} style={{borderBottomLeftRadius: 280, borderBottomRightRadius: 280, borderTopLeftRadius: 280, borderTopRightRadius: 280}} transition={transition}/><Image background={{alt: "", fit: "fill", ...toResponsiveImage_194x2gw(ZrWmJpbzC)}} className={"framer-e089ve"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"IclwKqYaI"} style={{borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-08lhz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-08lhz * { box-sizing: border-box; }", ".framer-08lhz .framer-1whk6mc { display: block; }", ".framer-08lhz .framer-1opdvy2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 559px; }", ".framer-08lhz .framer-6iumg8 { aspect-ratio: 1 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 559px); overflow: hidden; position: relative; width: 1px; will-change: transform; }", ".framer-08lhz .framer-e089ve { aspect-ratio: 1 / 1; bottom: 0px; flex: none; height: var(--framer-aspect-ratio-supported, 146px); left: 0px; overflow: hidden; position: absolute; width: 26%; will-change: transform; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-08lhz .framer-1opdvy2 { gap: 0px; } .framer-08lhz .framer-1opdvy2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-08lhz .framer-1opdvy2 > :first-child { margin-left: 0px; } .framer-08lhz .framer-1opdvy2 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 559
 * @framerIntrinsicWidth 559
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"IHI30114s":"image","ZrWmJpbzC":"logo"}
 */
const FramersesJRXpzp: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramersesJRXpzp;

FramersesJRXpzp.displayName = "Ar / Work Brand Preview";

FramersesJRXpzp.defaultProps = {height: 559, width: 559};

addPropertyControls(FramersesJRXpzp, {IHI30114s: {title: "Image", type: ControlType.ResponsiveImage}, ZrWmJpbzC: {title: "Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramersesJRXpzp, [])